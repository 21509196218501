@mixin svg-icon() {
  content: "";
  background-repeat: no-repeat;
  background-position: center;
}

@import "./common-styles/window.scss";

$header-background-color: #e7e7e7;
$body-container-background-color: #f4f4f4;

$font-family: "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;

$main-color: #1ab394;
$main-hover-color: darken($main-color, 5%);
$body-background-color: white;
$inputs-background-color: $body-background-color;
$text-color: #6d7072;
$header-color: $text-color;
$border-color: $header-background-color;

$error-color: #ed5565;
$error-background-color: lighten($error-color, 30%);

$header-height: 74%;
$font-size: 14px;

$base-line-height: 2em;

$slider-color: $body-background-color;
$label-disabled-color: rgba(64, 64, 64, 0.5);

.sv_main.sv_frame {
  .sv_container {
    max-width: 80%;
    margin: auto;
    padding: 0 1em;

    .sv_header {
      padding-top: 5em;
      padding-bottom: 1em;
    }
  }
}

.sv_main {
  background-color: $body-container-background-color;

  hr {
    border-bottom: 1px solid $border-color;
  }

  input[type="button"],
  button {
    color: $body-background-color;
    background-color: $main-color;

    &:hover {
      background-color: $main-hover-color;
    }
  }

  .sv_q_other input,
  .sv_q_text_root,
  .sv_q_dropdown_control,
  input:not([type="button"]):not([type="reset"]):not([type="submit"]):not([type="image"]):not([type="checkbox"]):not([type="radio"]),
  select,
  textarea {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid $border-color;
    color: $text-color;
    background-color: $inputs-background-color;
    opacity: 1;
    &:focus {
      border: 1px solid $main-color;
    }
  }

  .sv_select_wrapper {
    background-color: $body-background-color;
    &:before {
      background-color: $main-color;
    }
  }

  .sv_header {
    color: $header-color;
  }

  .sv_custom_header {
    background-color: $header-background-color;
  }

  .sv_container {
    color: $text-color;
  }

  .sv_body {
    background-color: $body-background-color;
    border-top: 2px solid $main-color;
  }

  .sv_progress {
    background-color: $border-color;
    margin-bottom: 2em;
  }

  .sv_progress_bar {
    background-color: $main-color;
  }

  .sv_p_root {
    & > .sv_row {
      border-bottom: 1px solid $border-color;

      &:nth-child(odd) {
        background-color: $body-background-color;
      }
      &:nth-child(even) {
        background-color: $body-container-background-color;
      }
    }
  }

  .sv_q_erbox:not([style*="display: none"]):not([style*="display:none"]) {
    border: 1px solid $error-color;
    background-color: $error-background-color;
    color: $error-color;

    & + input,
    & + textarea,
    & + .sv_select_wrapper select {
      border: 1px solid $error-color;
      &:focus {
        outline: $error-color auto 5px;
      }
    }
  }

  table.sv_q_matrix,
  table.sv_q_matrix_dropdown,
  table.sv_q_matrix_dynamic {
    tr {
      border-bottom: 1px solid $border-color;
    }
  }

  .sv_matrix_cell {
    vertical-align: top;
  }
  .sv_matrix_cell_detail {
    vertical-align: middle;
    text-align: center;
    width: 32px;
  }

  .sv-table__cell--choice {
    text-align: center;
  }

  .sv_matrix_cell_detail_button {
    width: 29px;
    height: 29px;
    min-width: 0px;
    padding: 0;
    outline: none;
  }
  .sv_matrix_cell_detail_button_expanded {
    background-color: lightgray;
    &:hover {
      background-color: darkgray;
    }
  }
  .sv_matrix_cell_detail_rowtext {
    vertical-align: middle;
  }
  .sv_q_m_cell_selected {
    color: $body-background-color;
    background-color: $main-hover-color;
  }

  .sv_q_rating_item {
    &.active {
      .sv_q_rating_item_text {
        background-color: $main-hover-color;
        border-color: $main-hover-color;
        color: $body-background-color;
      }
    }
    .sv_q_rating_item_text {
      border: 1px solid $border-color;
      &:hover {
        border: 1px solid $main-hover-color;
      }
    }
  }

  .sv_q_imgsel.checked label > div {
    background-color: $main-color;
  }

  .sv_q_file_remove:hover {
    color: $main-color;
  }
  .sv-boolean__switch {
    background-color: $main-color;
  }
  .sv-boolean__slider {
    background-color: $slider-color;
  }
  .sv-boolean__label--disabled {
    color: $label-disabled-color;
  }
}

.sv_main {
  position: relative;
  width: 100%;
  //background-color: $body-container-background-color;
  font-family: $font-family, "Helvetica Neue", Arial, sans-serif;
  font-size: $font-size;

  hr {
    border: none;
    //border-bottom: 1px solid $header-background-color;
  }

  input[type="button"],
  button {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    font-size: 0.85em;
    font-weight: bold;
    //color: $body-background-color;
    line-height: $base-line-height;
    //background-color: $main-color;
    border: none;
    min-width: 100px;
    cursor: pointer;
    padding: 0 2em;
    border-radius: 2px;

    &:hover {
      //background-color: $main-hover-color;
    }
  }

  .sv_custom_header {
    position: absolute;
    width: 100%;
    height: $header-height;
    max-height: 275px;
    //background-color: $header-background-color;
  }
  .sv_container {
    position: relative;
    //color: $text-color;

    .sv_header {
      padding: 1em;

      h3 {
        font-size: 2em;
        font-weight: 300;
        margin: 0;
      }
    }

    .sv_body {
      &.sv_completed_page {
        text-align: center;
        padding: 5em 1em;

        > h3 {
          margin: 0;
          font-size: 30px;
          font-weight: 300;
        }
      }

      //background-color: $body-background-color;
      padding: 1em 1em 1.6428em 1em;
      //border-top: 2px solid $main-color;

      .sv_progress {
        height: 0.3em;
        //background-color: $header-background-color;

        .sv_progress_bar {
          height: 100%;
          min-width: 100px;
          //background-color: $main-color;
          position: relative;
          margin-top: 2em;
          > span {
            position: absolute;
            top: -1.5em;
            display: inline-block;
            white-space: nowrap;
          }
        }
      }

      .sv_p_root {
        .sv_page_title {
          font-size: 1em;
          margin-top: 0.1em;
          font-weight: normal;
        }

        .sv_p_title {
          font-weight: bold;
          font-size: 1.15em;
          margin: 1.5em 0 0 0;
        }
        .sv_p_panel {
          margin: 0 0 1.5em 0;
        }
        & > .sv_row {
          //border-bottom: 1px solid $header-background-color;
          padding: 0;

          &:nth-child(odd) {
            //background-color: $body-background-color;
          }
          &:nth-child(even) {
            //background-color: $body-container-background-color;
          }
        }

        .sv_q_title {
          font-weight: bold;
          font-size: 1em;
          margin: 0.5em 0;
        }

        .sv_q--disabled .sv_q_title {
          color: $label-disabled-color;
        }

        .sv_q_description {
          margin-top: -0.6em;
          min-height: 0.6em;
        }
        .sv_q_flow {
          .sv_q_checkbox_inline,
          .sv_q_radiogroup_inline,
          .sv_q_imagepicker_inline {
            line-height: $base-line-height;
            display: inline-block;
          }
        }
        .sv_q {
          padding: 0.5em 1em 1.5em 1em;
          box-sizing: border-box;
          overflow: auto;

          .sv_panel_dynamic {
            .sv_q_title {
              font-weight: normal;
            }

            .sv-paneldynamic__progress-container {
              position: relative;
              display: inline-block;
              width: calc(100% - 250px);
              margin-left: 40px;
              margin-top: 10px;
            }
          }

          .sv_q_erbox:not([style*="display: none"]):not([style*="display:none"]) {
            //border: 1px solid $error-color;
            //background-color: $error-background-color;
            margin: 1em 0;
            //color: $error-color;
            padding: 1em;

            & + input,
            & + textarea,
            & + .sv_select_wrapper select {
              //border: 1px solid $error-color;
              &:focus {
                //outline: $error-color auto 5px;
              }
            }
          }

          .sv_q_other input,
          .sv_q_text_root,
          .sv_q_dropdown_control,
          input:not([type="button"]):not([type="reset"]):not([type="submit"]):not([type="image"]):not([type="checkbox"]):not([type="radio"]),
          select,
          textarea {
            position: relative;
            width: 100%;
            box-sizing: border-box;
            //border: 1px solid $header-background-color;
            font-size: 1em;
            line-height: $base-line-height;
            //color: $text-color;
            padding-left: 1em;
            //background-color: $inputs-background-color;

            &:focus {
              //border-color: $main-color;
              outline: none;
              //border: 1px solid $main-color;
            }
          }

          .sv_q_other input,
          .sv_q_dropdown_control,
          input:not([type="button"]):not([type="reset"]):not([type="submit"]):not([type="image"]):not([type="checkbox"]):not([type="radio"]),
          select {
            height: calc(#{$base-line-height} + 1px);
          }

          div.sv_q_text_root,
          div.sv_q_dropdown_control {
            min-height: 2.43em;
            min-width: 7em;
          }

          .sv_select_wrapper {
            width: 100%;
            position: relative;
            display: inline-block;
            //background-color: $body-background-color;

            select {
              display: block;
              background: transparent;
              appearance: none;
              -webkit-appearance: none;
              -moz-appearance: none;
              padding-right: 2.5em;

              &::-ms-expand {
                display: none;
              }
            }

            &:before {
              padding: 1em;
              position: absolute;
              right: 1px;
              right: 0;
              top: 1px;
              //background-color: $main-color;
              background-image: url("./images/ArrowDown_34x34.svg");
              @include svg-icon();
              //height: 2em;
              z-index: 1;
              pointer-events: none;
            }
          }

          input[type="color"] {
            min-height: $base-line-height;
            padding: 0;
          }

          input[type="radio"],
          input[type="checkbox"] {
            margin: 0;
            margin-right: 0.55em;
            width: 1.2em;
            height: 1.2em;
            vertical-align: middle;
            font-size: 1em;
            margin-top: -0.1em;

            &:focus {
              outline: 1px dotted $main-color;
            }
          }

          .sv_q_radiogroup.sv_q_radiogroup_inline:not(:last-child),
          .sv_q_checkbox.sv_q_checkbox_inline:not(:last-child),
          .sv_q_imgsel.sv_q_imagepicker_inline:not(:last-child) {
            margin-right: 1.5em;
          }

          .sv_q_imgsel label > div {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            padding: 4px;
            border: 1px solid lightgray;
            border-radius: 4px;
            > img {
              display: block;
              pointer-events: none;
              position: relative;
            }
          }

          // .sv_q_radiogroup,
          // .sv_q_checkbox {
          //   $total-columns: 5;
          //   @for $i from 1 through $total-columns {
          //     &.sv-q-col-#{$i} {
          //       width: 100% / $i;
          //       display: inline-block;
          //       @media only screen and (max-width: 480px) {
          //         width: 100%;
          //       }
          //     }
          //   }
          // }

          .sv_q_radiogroup_clear {
            margin-top: 0.5em;
          }

          .sv_q_checkbox_inline,
          .sv_q_radiogroup_inline,
          .sv_q_imagepicker_inline {
            line-height: $base-line-height;
            display: inline-block;
          }

          .sv_q_footer {
            padding: 1em 0;
          }
        }

        table {
          width: 100%;
          border-collapse: collapse;

          &.sv_q_matrix,
          &.sv_q_matrix_dropdown,
          &.sv_q_matrix_dynamic {
            .sv_matrix_dynamic_button {
              padding: 0.3em 2em;
            }
            tr {
              //border-bottom: 1px solid $header-background-color;
            }
          }
          &.sv_q_matrix {
            td,
            th {
              padding: 0.5em 1em;
              text-align: center;
            }

            td {
              @media (min-width: 768px) {
                min-width: 10em;
              }
              .sv_q_m_label {
                position: static;
                display: block;
                width: 100%;
              }

              &:first-child {
                text-align: left;
              }
            }
          }
          &.sv_q_matrix_dropdown {
            .sv_qcbc {
              .sv_q_checkbox_control_label {
                margin-right: 1.5em;
              }
            }
          }

          td,
          th {
            padding: 0 1em;
          }

          td {
            padding: 0.5em;
          }

          th {
            line-height: 1.3em;
            padding: 0.5em;
            vertical-align: bottom;
            font-weight: bold;
          }
        }

        fieldset.sv_qcbc {
          line-height: $base-line-height;
          padding-top: 0.1em;
        }

        .sv_q_checkbox_label,
        .sv_q_radiogroup_label {
          display: block;
        }

        .sv_q_other {
          margin-left: 1em;
        }

        .sv_q_select_column {
          display: inline-block;
          vertical-align: top;
          min-width: 10%;
        }

        .sv_q_rating {
          line-height: $base-line-height;

          .sv_q_rating_item {
            cursor: pointer;
            word-spacing: -0.3em;
            font-weight: normal;
            display: inline;

            > * {
              word-spacing: initial;
              @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                word-spacing: normal;
              }
            }

            &.active {
              .sv_q_rating_item_text {
                //background-color: $main-hover-color;
                //border-color: $main-hover-color;
                //color: $body-background-color;
              }
            }
            .sv_q_rating_item_text {
              display: inline-block;
              min-width: $base-line-height;
              padding: 0 0.3em;
              margin-left: -1px;
              //border: 1px solid $header-background-color;
              text-align: center;
              &:hover {
                //border: 1px solid $main-color;
              }
            }
            &:not(:nth-child(2)) {
              .sv_q_rating_item_text:not(:hover) {
                border-left-color: transparent;
              }
            }
          }
          .sv_q_rating_min_text {
            margin-right: 0.5em;
          }
          .sv_q_rating_max_text {
            margin-left: 0.5em;
          }
        }
      }

      .sv_nav {
        padding: 1em 0;
        min-height: $base-line-height;

        .sv_start_btn,
        .sv_next_btn,
        .sv_complete_btn {
          float: right;
        }
        .sv_preview_btn {
          float: right;
        }
        .sv_prev_btn {
          float: left;
        }
      }
    }
  }

  .sv_q_image {
    display: inline-block;
  }
}
.sv_main.sv_main .sv-action-bar-item.sv_edit_btn {
  color: $body-background-color;
  background-color: $main-color;
  float: left;
  margin-bottom: 7px;
}
.sv_main.sv_main .sv-action-bar-item.sv_edit_btn:hover {
  background-color: $main-hover-color;
}

// https://github.com/surveyjs/survey-library/issues/2559
.sv_main .sv_p_root .sv_row {
  .sv_q.sv_qstn {
    &:first-child:last-child {
      flex: none !important;
    }
  }
}

@import "./main.rtl.scss";
@import "./main.m600.scss";

.sv_main {
  .sv_qstn .sv_q_file {
    .sv-visuallyhidden {
      position: absolute !important;
      opacity: 0;
    }
    .sv_q_file_choose_button {
      display: inline-block;
      box-sizing: border-box;
      min-width: 100px;
      line-height: 2em;
      padding: 0.25em 2em;
      font-size: 0.85em;
      font-weight: bold;
      border: none;
      border-radius: 2px;
      cursor: pointer;
    }
    .sv_q_file_remove_button {
      padding: 0.25em 2em;
    }
    .sv-file__decorator {
      display: inline-block;
      min-width: 250px;

      .sv_q_file_placeholder {
        margin-left: 1em;
        display: inline-block;
      }
    }
  }
}

.sv-visuallyhidden {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

@import "./signaturepad.scss";
@import "./boolean-checkbox.scss";

.sv-dragdrop-movedown {
  transform: translate(0, 0);
  animation: svdragdropmovedown 0.1s;
  animation-timing-function: ease-in-out;
}

@keyframes svdragdropmovedown {
  0% {
    transform: translate(0, -50px);
  }
  100% {
    transform: translate(0, 0);
  }
}

.sv-dragdrop-moveup {
  transform: translate(0, 0);
  animation: svdragdropmoveup 0.1s;
  animation-timing-function: ease-in-out;
}

@keyframes svdragdropmoveup {
  0% {
    transform: translate(0, 50px);
  }
  100% {
    transform: translate(0, 0);
  }
}
